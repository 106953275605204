import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import BaseProductCard from "@/components/BaseProductCard.vue";
import { getProductList } from "@/api/ProductApi";
import { useRoute } from "vue-router";
import { debounce } from "lodash";
export default defineComponent({
    name: "home",
    components: {
        BaseProductCard: BaseProductCard,
    },
    setup: function () {
        var _this = this;
        var route = useRoute();
        var products = ref([]);
        var loading = ref(false);
        var product = ref();
        var fullProductRoute = ref("");
        var pagination = ref();
        var loadMoreLoading = ref({
            load: false,
        });
        var currentInfinitePage = ref({
            page: 1,
            isEnd: false,
            totalPage: 0,
        });
        var getListOfProducts = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        // topLoadingToggle(true);
                        return [4 /*yield*/, getProductList(payload)
                                .then(function (res) {
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                                products.value = res.data.data;
                                currentInfinitePage.value = {
                                    page: 1,
                                    isEnd: res.data.meta.last_page === 1,
                                    totalPage: res.data.meta.last_page,
                                };
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        // topLoadingToggle(true);
                        _a.sent();
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        watch(function () { return route.params; }, function (params) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                getListOfProducts({
                    category_id: params.categoryId,
                });
                setCurrentPageBreadcrumbs(((_a = params.categoryName) !== null && _a !== void 0 ? _a : "All Category"), ["Category"]);
                return [2 /*return*/];
            });
        }); });
        var handleScroll = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var element, scrollWidth, fixedHeight, isBottom;
            return __generator(this, function (_a) {
                element = e.currentTarget;
                scrollWidth = parseInt(element.scrollHeight) - parseInt(element.scrollTop);
                fixedHeight = parseInt(element.clientHeight);
                isBottom = scrollWidth >= fixedHeight - 1 && scrollWidth <= fixedHeight + 1;
                if (isBottom) {
                    if (!currentInfinitePage.value.isEnd) {
                        getInfiniteProductList(currentInfinitePage, products);
                    }
                }
                return [2 /*return*/];
            });
        }); };
        var handleMouseEnter = function () {
            getInfiniteProductList(currentInfinitePage, products);
        };
        var getInfiniteProductList = debounce(function (currentInfinitePage, products) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadMoreLoading.value.load = true;
                        return [4 /*yield*/, getProductList({
                                category_id: route.params.categoryId,
                                page: ++currentInfinitePage.value.page,
                            })
                                .then(function (res) {
                                var _a;
                                (_a = products.value).push.apply(_a, res.data.data);
                                var lastPage = res.data.meta.last_page;
                                if (currentInfinitePage.value.page >= lastPage) {
                                    currentInfinitePage.value.isEnd = true;
                                    --currentInfinitePage.value.page;
                                }
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loadMoreLoading.value.load = false;
                        return [2 /*return*/];
                }
            });
        }); }, 250);
        onMounted(function () {
            var _a;
            setCurrentPageBreadcrumbs(((_a = route.params.categoryName) !== null && _a !== void 0 ? _a : "All Category"), ["Category"]);
            getListOfProducts({ category_id: route.params.categoryId });
        });
        return {
            products: products,
            loading: loading,
            product: product,
            fullProductRoute: fullProductRoute,
            pagination: pagination,
            loadMoreLoading: loadMoreLoading,
            currentInfinitePage: currentInfinitePage,
            handleMouseEnter: handleMouseEnter,
            handleScroll: handleScroll,
        };
    },
});
